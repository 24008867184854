.profile{
    width: 100vw;
}

.profile > .container{
    margin-top: 30px;
}

.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 10px lightgray solid;
    border-top: 10px lightskyblue solid;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    animation: spin 2s linear infinite ;
}

@keyframes spin{
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}