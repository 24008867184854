body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
}
body > iframe{
  display: none !important;
}

body::-webkit-scrollbar{
  width:6px;
}

body::-webkit-scrollbar-track{
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb{
  border-radius: 20px;
  /* border: 3px solid #1e1e24; */
  background: #6649b8;
}

html {
  height: 100%;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}