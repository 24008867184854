.mainpage{
  /* top: 61.52px; */
  top: 0px;
  /* position: absolute; */
  background-color: white;
  overflow-x: hidden;
}

.background{
    width:100%;
    background-repeat: no-repeat;
    background-size: 100%;
}



.backgroundContainer{
  color: snow;
  text-align: center;
  position: relative;
  z-index: 0;
}

.kQuoteH{
  font-family: "fangsong" ;
}

.kQuote{
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.kQuoteF{
  font-family: serif;
  font-size: .8em;
}

.invitation{
  display: none;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  /* padding: 0px 20px; */
  color:snow;
  /* z-index:1;
  pointer-events:none; */
}
#invitationText{
  font-size: 1.4em;
}
.social a{
  text-decoration: none !important;
}
.opensealink{
  width: 250px;
}

@-webkit-keyframes _opacity {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes _opacity {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.heightProperty{
  height: calc(100vh - 61.52px);
}
@media (max-width: 1400px){
  .kQuote{
    top:40%;
  }
}

@media (max-width:1274px){
  .kQuoteH{
    font-size: 1.7em;
  }
}
@media (max-width:1199px){
  .invitationbelow{
    display:none;
  }
  .invitation{
    display: block;
  }
}
@media (max-width:991px){
  .kQuote{
    display: none;
  }

}

@media (max-width:620px){
  #invitationText{
    font-size: 1.4em;;
  }

  .mainpage{
    margin-top: 61.52px
}
}
@media (max-width:456px){
  #invitationText{
    font-size: 1.2em;;
  }
}

.bottomcontainer{
  padding:0;
  margin:0;
  color: rgb(34, 30, 70)
  /* background-color: blue; */
}

.bottomcontainer p{
  font-size: 1.4em;
  font-weight: 300;
}

.bottomcontainer h2{
  /* font-size: 2.4em; */
  text-transform: uppercase;
  font-weight: 245;
}

.tosLink{
  font-size: 1.3em;
  color: snow;
}
.tosLink:hover{
  text-decoration: underline snow;
}