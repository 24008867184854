.slider {
  margin-top: 60px;
}

.slider img {
  height: 400px;
}
.slider p {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* .slider strong{
  font-weight:900px;
} */

.from-left {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.from-right {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.from-left,
.from-right {
  transition: opacity 250ms ease-in, -webkit-transform 400ms ease-in;
  transition: opacity 250ms ease-in, transform 400ms ease-in;
  transition: opacity 250ms ease-in, transform 400ms ease-in,
    -webkit-transform 400ms ease-in;
  opacity: 0;
}

.from-left.appear,
.from-right.appear {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

@media (max-width: 991px) {
  .slider p {
    margin-top: 10px;
    position: unset;
    top: 0%;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@media (max-width: 700px) {
  .slider img {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .slider img {
    height: 210px;
  }
}
@media (min-width: 1200px) {
  .from-right p {
    transform: translate(-10%, -50%);
  }
}
