.popup-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1100;
}
.disclaimertext{
margin: auto;
margin-top: 15px;
-webkit-font-smoothing: antialiased;
font-size: 11px; 
font-weight: normal; 
text-align: center; 
transition: opacity 0.2s linear 0s; 
color: rgb(118, 118, 118); 
width: 250px;
}

.loginform {
  width: 23rem;
  left:50%;
  transform: translateX(-50%);
  top:25vh;
  z-index: 1200;
}
.crossbutton{
  height: 40px;
  width: 40px;
  position: relative;
  top: 8px;
  border-radius: 100% !important;
}
.crossbutton:hover{
  background-color: rgb(247, 240, 240);
}

.btnFacebook { 
  /* padding-right:20px; */
  font-size: 14px;
  border-radius: 4px;
  background: #3b5998;
  color:snow;
  border:0px transparent;  
  text-align: center;
  display: inline-block;
}

.btnFacebook:hover {
  opacity: .9;
}

.loginbuttons {
  margin: 5px;
  height: 40px;  
  width: 205px;
}

.googlebutton{
  width: min-content;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 44px;
}