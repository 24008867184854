.transformationPage {
    width: 100%;
}

.transformationPage p {
    font-size: 1.1em;
    font-weight: 300;
}

.transformationPage h4 {
    font-weight: 300;
}

.btn-purple,
.btn-purple:hover,
.btn-purple:active,
.btn-purple:visited {
    background-color: #396f82 !important;
    border-color: #396f82 !important;
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow:
            .27em 0 0 rgba(0, 0, 0, 0),
            .54em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: black;
        text-shadow:
            .27em 0 0 rgba(0, 0, 0, 0),
            .54em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow:
            .27em 0 0 black,
            .54em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow:
            .27em 0 0 black,
            .54em 0 0 black;
    }
}

.editingBehavior {
    color: snow;
    width: 100%;
    background-color: #282c34;
}

.inputContainer {
    width: 100%;
}

.inputImageEmpty {
    height: 100%;
    width: 100%;
}

.emptyBorder {
    border-radius: 15px;
    height: 100%;
    width: 100%;
    border: rgb(20, 26, 80) 1px dashed;
}

/* .imageCol{
        padding-bottom: 30px;
        background-color: #f8f6f6 !important;
        border-radius: 5px;
        border: solid lightgrey 1px;
        position: relative;
        padding-top: 10px;
    } */
.inputImage {
    max-height: 100%;
}

.imageHolder {
    position: relative;
    max-width: 680px;
    margin: auto;
    height: 520px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.uploadImageButton {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.deleteInput {
    margin-left: 5px;
}

.deleteButton {
    background: none;
    color: inherit;
    border: none;
    width: 20px;
    position: relative;
    bottom: 1.5px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    outline: none;
    box-shadow: none;
}

.styleImage {
    height: inherit;
    width: auto;
}

.deleteStyleButton {
    position: absolute;
    bottom: 0%;
    right: 50%;
    transform: translate(50%, -20%);
}

.deleteStyleButton2 {
    position: absolute;
    z-index: 2;
    bottom: 0%;
    right: 0%;
    transform: translate(-30%, -50%);
}


.centeredItem {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.carouselClass {
    /* background-color: #b4b2b2 !important; */
    border-radius: 5px;
    z-index: 2;
    width: 100%;
    margin: auto;
    height: 100%;
}

/* Overwrites the next button color for carousel */
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%0' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 3.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

/* Overwrites the previous button color for carousel */
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%0' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: white !important;
    border-radius: 4px;
}

.carousel-item.active {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carouselPic {
    max-height: 520px;
}

#dropdown-choose-style {
    color: slateblue;
    position: relative;
    bottom: 3.3px;
    left: 5px;
    padding: 0 0.30rem;
}


@media (max-width: 991px) {
    .transformButton {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .transformButton {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -25%);
    }
}


@media (max-width:1500px) {
    .imageHolder {
        height: 400px;
        max-width: 540px;
    }

    .carouselPic {
        height: 400px;
    }
}

@media (max-width:1290px) {
    .imageHolder {
        height: 400px;
        max-width: 500px;
    }

    .carouselPic {
        height: 400px;
    }
}

@media (max-width:620px) {
    .imageHolder {
        height: 300px;
        max-width: 400px;
    }

    .carouselPic {
        height: 300px;
    }
}

@media(max-width:450px) {
    .imageHolder {
        height: 250px;
        max-width: 325px;
    }

    .carouselPic {
        height: 250px;
    }
}


/* Proccess bar */
.procborder {
    border-radius: 4px;
}

@keyframes down {
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

.barcontainer {
    margin-bottom: 10px;
    width: '80%';
    padding-right: 0px;
    padding-left: 0px;
    animation: down 2s forwards;
    transition: margin 0.5s ease-in !important;
}

.barcontainer.hide {
    padding: 0px;
    margin: 0px;
    opacity: 0 !important;
}

.barcontainer.hide>.procborder {
    transition: font-size 0.5s ease-in;
    font-size: 0;
    border: none !important;
}

.barcontainer.hide>.procborder img {
    transition: height 0.5s ease-in;
    height: 0;
}

.downloader {
    position: fixed;
    width: 50px;
    height: 50px;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    z-index: 1200;
}


.spinner {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 10px lightgray solid;
    border-top: 10px lightskyblue solid;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    animation: spin 2s linear infinite ;
}

@keyframes spin{
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}