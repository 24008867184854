.App {
  text-align: center;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.App p,
label {
  font-size: 1.1em;
  font-weight: 300;
}
.App h1 {
  font-weight: 300;
}
.App h2 {
  font-weight: 300;
}
.App h3 {
  font-weight: 300;
}
.App h4 {
  font-weight: 300;
}
.App h5 {
  font-weight: 300;
}
.App h6 {
  font-weight: 300;
}

.navigationbar {
  z-index: 5;
  transition: background 250ms ease-in;
  overflow-x: hidden;
  /* font-family: "Poppins"; */
  /* color:black; */
  /* text-transform: uppercase; */
  font-size: 1.1em;
}
.navbar-brand h3 {
  font-weight: 300;
}

.nav-link span {
  font-weight: 300;
  color: snow;
}
.nav-link span:hover {
  font-weight: 300;
  color: rgb(233, 233, 233);
}

.loginbutton {
  color: snow !important;
  /* height: 42px; */
}

.userinfobutton {
  margin-right: 5px;
}

.userinfo{
  margin-right: 20px;
}

.navitem {
  margin-left: 15px;
}

@media (min-width: 992px) {
  .navigationbar {
    overflow-y: hidden;
  }
}

@media (max-width: 991px) {
  .navigationbar {
    background-color: #396f82 !important;
  }
  .userinfobutton {
    margin-right: 0px;
  }
  .userinfo{
    margin-right: 0px;
  }
}
