.IOImages{
    height: 300px;
}

.fadeIn{
    -webkit-animation: fadeIn;
    /* Safari 4+ */
    /* Fx 5+ */
    /* Opera 12+ */
    animation: fadeIn;
    /* IE 10+, Fx 29+ */
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
}
.carouselContainers{
}
.carouselButton{
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
    outline: inherit;
    width: 100%;
    outline: none;
    box-shadow: none;
}
.carouselButton:focus{
    outline:0;
}

.fadeOut{
    -webkit-animation: fadeOut;
    /* Safari 4+ */
    /* Fx 5+ */
    /* Opera 12+ */
    animation: fadeOut;
    /* IE 10+, Fx 29+ */
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;

}
.showCaseTitle{
    font-size: 1.7em;
    font-weight:200;
    /* font-family: sans-serif, 'Helvetica Neue'; */
}
@media (max-width: 1199px) {
    .showcaseCarousel:not(:first-of-type){
        margin-top: 7px;
    }
}

@media (max-width: 725px){
    .IOImages{
        height: 250px;
    }
}
@media (max-width: 400px){
    .IOImages{
        height: 200px;
    }
}

@-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
@keyframes fadeIn {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}

@-webkit-keyframes fadeOut {
0% {
    opacity: 1;
}
100% {
    opacity: 0;
}
}

@keyframes fadeOut {
0% {
    opacity: 1;
}
100% {
    opacity: 0;
}
}